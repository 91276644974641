let config = {
    s3: {
        REGION: "ap-southeast-2",
        BUCKET: "forwood-ausgrid-prod-forwoodid",
        BATCH_PROCESS_BUCKET_NAME: "forwood-ausgrid-prod-forwoodid-batch-user-process"
    },
    appSyncAPI: {
        REGION: "ap-southeast-2",
        URL: "https://rzgluzudqjhonb35prrex5fvo4.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    },
    apiGateway: {
        REGION: "ap-southeast-2",
        URL: "https://rrzf0zw7f9.execute-api.ap-southeast-2.amazonaws.com/env",
        USER_TOKEN_URL: "https://gryq6vrex0.execute-api.ap-southeast-2.amazonaws.com/prod"
    },
    configurationService: {
        URL: "https://config.ausgrid.forwoodsafety.com",
        WEBSOCKET: "wss://m658sl3l7l.execute-api.ap-southeast-2.amazonaws.com/prod"
    },
    cognito: {
        REGION: "ap-southeast-2",
        USER_POOL_ID: "ap-southeast-2_9OUk0Y2hP",
        APP_CLIENT_ID: "3ckd44dqdejcjfbsn91r9hp641",
        IDENTITY_POOL_ID: "ap-southeast-2:1fd70a79-40e1-4f31-be9b-d4366ed009c5",
        USERPOOL_HOSTED_DOMAIN: "forwood-ausgrid-id-prod",
        USERPOOL_ADFS_CALLBACK_URL: "https://id.ausgrid.forwoodsafety.com",
        USERPOOL_ADFS_LOGOUT_URL: "https://id.ausgrid.forwoodsafety.com",
        AUTHENTICATED_ROLE_ARN: "arn:aws:iam::825765428556:role/prod-CA",
        get USERPOOL_ADFS_LAUNCH_URL () {
          return 'https://'+this.USERPOOL_HOSTED_DOMAIN+'.auth.'+this.REGION+'.amazoncognito.com/authorize?redirect_uri='+this.USERPOOL_ADFS_CALLBACK_URL+'&response_type=code&client_id='+this.APP_CLIENT_ID;
        }
    },
    samlProvider: {
      NAME: '',
      SUPPORTED_LIST: 'ForwoodAzure'
    },
    reactApp: {
        VERSION: "1.34.3",
        HOSTNAME: "id.ausgrid.forwoodsafety.com",
        COOKIE_DOMAIN: ".ausgrid.forwoodsafety.com",
        SECURE_PROTOCOL: true,
        FORWOOD_COMPANY_ID: "a542ae2b-6931-4307-8ad6-ae03c182c6ca",
        FORWOOD_HOMEPAGE_MICROFRONTEND_URL: "https://homepage.ausgrid.forwoodsafety.com"
    }
};

export default config;
